import React from "react";

const OurCulture = () => {
  return (
    <section className="about-culture" id="about_culture">
      <div className="about-culture-content">
        <div className="introduction">
          <h2>Our Culture At Ra'Asis Is What Drives Our Productivity & Ignites Our Working Spirit</h2>
          <p>These are the core values that define and guide who we are, what we are, what we do and how we do it.</p>
        </div>
        <div className="core-values">
          <div className="core-value">
            <i class="bx bx-search-alt"></i>
            <h3>Transparency</h3>
            <p id="color_diff">Transparency is at the heart of everything we do. We believe in open communication, honesty, and accountability when dealing with all our clients.</p>
          </div>
          <div className="core-value">
            <i class="bx bx-run"></i>
            <h3>Agility</h3>
            <p>We embrace agility by being flexible, adaptive, and responsive to changes in every project. We value innovation, creativity, and continuous improvement.</p>
          </div>
          <div className="core-value">
            <i class="bx bx-award"></i>
            <h3>Quality Assurance</h3>
            <p>Quality assurance is ingrained in our culture. We strive for excellence in everything we deliver, ensuring high standards and customer satisfaction.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurCulture;
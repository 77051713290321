import React from "react";
import OurCulture from "./OurCulture";

const AboutDetailed = () => {
  return (
    <section className="about-detailed" id="robust_secure">
        <div className="about-detailed-content">
            <h2>We Craft Innovative Robust & Secure Software</h2>
            <p>Ra'Asis Software operates as one of the Software & IT Consulting & Services Agents at Transfrontières Agencies Limited, a Private Limited Company registered in Kenya. We help you evolve your business into a profitable venture by connecting it to our pool of highly skilled Software & IT professionals. This connection facilitates businesses and brands to grow in crucial areas such as Software & Systems Engineering, Cloud Computing, IoT, Artificial Intelligence & Automation, Graphics Design and much more. We combine senior-level software and technical support with practical real-world business applications in a number of industries including education, sports, finance & banking, eCommerce, media & entertainment, building & construction and many others.</p>
        </div>
        <OurCulture />
    </section>
  );
}

export default AboutDetailed;
